(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

$('.bxslider').bxSlider({
  mode: 'fade',
  auto: true,
  pause: 8000,
  speed: 2000,
  controls: false,
  pager: false
});

$('.instruction_bxslider').bxSlider({
  mode: 'fade',
  auto: true,
  pause: 4000,
  speed: 2000,
  controls: false,
  pager: true
});

$('.filter').on('click', function (e) {
  e.preventDefault();
});

$('#instruction_container').mixItUp({
  load: {
    filter: '.all_categories'
  },
  animation: {
    duration: 400,
    effects: 'fade'
  }
});

$('.instruction_category_select').change(function () {
  var category = $('option:selected', this).data('category');
  $('.filter.' + category).click();
});

},{}]},{},[1]);
